import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://paketo.io/images/paketo-logo-full-color.png" className="App-logo" />
      </header>
    </div>
  );
}

export default App;
